import React from 'react';
import Layout from '../components/layout';
import { graphql, useStaticQuery, Link } from 'gatsby';

const About = () => {

    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata{
                title
                author
            }
        }
    }
    `)

    return (
        <Layout>
            <h1>About me and this website</h1>
            <p>Hey there!  My name is {data.site.siteMetadata.author}, and I am a nerd.  
                I love jigsaw puzzles, Scrabble, Star Wars, and math.  
                I’m also an avid sports fan, though I haven’t played much myself (I’m a nerd, remember?).  
                I drink a lot of Kentucky bourbon and eat a lot of pizza.  
                My girlfriend Emilia is pretty high on my list of favorite things, too.  
                My biggest love of all, though, is data.</p>
            <p>While working my day job at a large commercial bank, I came across static site generation.  
                I was looking for more creative ways to tell stories with data. 
                Before I discovered static site generation, I was using Excel, PowerPoint, Python and occasionally Tableau to try and tell my stories.  
                No matter how hard I tried or how creative I got, no combination of these programs allowed me to do what I wanted, 
                which is to tell awesome, interactive stories with data. </p>
            <p>
                Eventually I came across Gatsby, which got me interested in learning Javascript and React.  
                My vision for this site is to put all of these elements together, a place where sports, data, and coding collide.
            </p>
            <p>
                Originally, I just wanted to showcase my horse racing analysis.  
                Horse racing is my favorite sport (that’s where my taste for Kentucky bourbon comes from), 
                and it’s where I started to play around with putting all my different interests together.  
                After spending a lot of my time in that arena, however, I found football data.  
                I thought it could be fun to create models that would produce optimal daily fantasy lineups.
            </p>
            <p>
                And thus, this website was born.  
            </p>
            <p>The sports that I currently cover are:</p>
                <ul>
                <li>Thoroughbred horse racing</li>
                <li>Soccer (Euro)</li>
                <li>National Football League</li>
                </ul>
            <p>In the future I also plan to add coverage for:</p>
                <ul>
                <li>National Basketball Association</li>
            <li>Major League Baseball</li>
            </ul>
            <p>
                Right now, the <Link to='/blog'>blog</Link> section is serving as a place for me to log the work that has gone into the development of the site.  
                I hope to evolve the blog into a place for some of the analysis on horse racing and a log for other work that I do.
            </p>
            <p>
                The <Link to ='/cards'>card</Link> section of the site is currently the home to the analysis that I would typically output to Google Sheets.  
                Instead of formatting data that I paste into Google Sheets for betting on horse racing that I do, 
                I thought that a special section of the site might serve as a good way to experiment with React and Gatsby, 
                and present the data analysis that I perform in a better way.
            </p>
        </Layout>
    )
}

export default About;